import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import { bulkAirtime } from "../../data/local/reducers/miscellaneous.reducer";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../SelectValue/Loading";
import { showErrorToast, showSuccessToast } from "../../utils/api-utils";

function BulkAirtimeForm() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const phoneNumber = data.phonenumber;
  const token = useSelector((state) => state.user.loginProfile.token);
  const users = useSelector((state) => state.misc);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    walletaccount: "",
    uploadfile: null,
    securityAnswer: "", // Added securityAnswer field
  };

  const validationSchema = Yup.object({
    uploadfile: Yup.mixed().required("Required"),
    securityAnswer: Yup.string().required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    const { uploadfile, securityAnswer } = values;
    const formData = new FormData();
    formData.append("phonenumber", phoneNumber);
    formData.append("contactFile", uploadfile);
    formData.append("security_answer", securityAnswer);
    formData.append("channel", "Web");
    formData.append("token", token);

    setIsLoading(true);

    try {
      const { payload } = await dispatch(bulkAirtime(formData));
      if (payload.status_code === "0") {
        resetForm();
        const message = `Transaction Successful: Amount Charged - N${payload.result.amountCharge}, Success Count - ${payload.result.success}`;
        showSuccessToast(message);
      } else {
        resetForm();
        showErrorToast("Bulk Operation Not Successful");
      }
    } catch (error) {
      console.error("Error during bulk upload:", error);
      showSuccessToast("An error occurred during bulk upload.");
    }

    setIsLoading(false);
  };

  return (
    <div>
      <Loading open={users.loading} />

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-2">
              <FormikControl
                control="input"
                type="text"
                label="Wallet number:"
                name="walletaccount"
                value={phoneNumber}
                disabled
              />

              <div className="flex items-center">
                <input
                  type="file"
                  id="uploadfile"
                  name="uploadfile"
                  onChange={(event) => {
                    const file = event.target.files[0];
                    if (file) {
                      setFieldValue("uploadfile", file);
                    }
                  }}
                  className="border rounded-md border-gray-200 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 file:py-4 file:px-4 file:bg-[#127EC8] file:text-white file:text-xs file:border-0"
                />
              </div>

              <FormikControl
                control="input"
                type="password"
                label="Security Answer:"
                name="securityAnswer" // Updated to reference securityAnswer
              />
            </div>

            <div className="grid grid-cols-1 px-4 md:grid-cols-2">
              <Button name={"Continue"} type="submit" />
            </div>
            {isLoading && <Loading open={isLoading} />}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default BulkAirtimeForm;
