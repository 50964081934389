import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import Back from "../Back";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../SelectValue/Loading";
import {
  fetchSecurityQuestions,
  verifyOTP,
} from "../../data/local/reducers/user.reducer";

const validationSchema = Yup.object({
  otp: Yup.string()
    .required("Required")
    .min(7, "Must be exactly 7 digits")
    .max(7, "Must be exactly 7 digits"),
});

function OtpForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user);
  const location = useLocation();
  const initialValues = {
    otp: "",
  };
  const onSubmit = async (values) => {
    const { otp } = values;
    const { payload } = await dispatch(verifyOTP(otp));
    if (payload.status_code === "0") {
      await dispatch(fetchSecurityQuestions());
      navigate("/registration", { state: { info: location.state.info } });
    }
  };
  return (
    <div className="mx-4 md:mx-16 h-screen grid content-center text-sm">
      <Loading open={users.loading} />
      <div className="bg-white rounded-md px-8 md:px-10 py-16">
        <div className="flex justify-between items-center">
          <Back />
          <Link to="/login">
            <p className=" text-button-color font-bold">Sign in</p>
          </Link>
        </div>

        <div className="pt-8">
          <p className="text-pri-text font-bold">Register with BVN</p>
          <p className="opacity-50">
            An OTP has been sent to the phone number linked to your BVN.
          </p>
        </div>

        <div className="py-8">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, errors, handleChange, touched }) => (
              <Form>
                <FormikControl
                  control="input"
                  type="text"
                  values={values.otp}
                  onchage={handleChange}
                  error={touched.otp && errors.otp}
                  label="OTP"
                  name="otp"
                />
                <div className=" grid grid-cols-1 mt-6">
                  <Button name="Confirm" />
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div>
          <Link to="/login">
            <p className=" text-red-500 "> Cancel</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default OtpForm;
